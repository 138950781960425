<template lang="pug">
  v-card
    v-card-title
        | Upload content
        v-spacer
            v-icon(aria-label="Close" @click="$emit('close')")
                | mdi-close
    v-card-text
        v-row
            v-col(cols="12" md="12")
                formStepper(:model ='model', :contentUpload="contentUpload" :onSave='upload' :update="false")
</template>

<script>
    /* eslint-disable */
    import Stepper from '../../steppers/UploadStepper'
    import loaders from '@/plugins/mixins/loaders'
    export default {
        name: 'Upload',

        components: {
            formStepper: Stepper,
        },
        mixins: [loaders],
        data () {
            return {
                model:{
                    title: '',
                    description: '',
                    category: '',
                    subcategory: '',
                    thumbnail: '',
                    url: '',
                    duration: '',
                    location:'',
                    type: '',
                    lastModified: '',
                    size: '',
                },
            }
        },
        props: {
            contentUpload: {
                type: Object,
                default: function () {
                    return {}
                },
            },
        },

        computed: {

        },

        methods: {

            upload () {
                const vm = this
                vm.$emit('close')
                vm.$swal({
                    title: 'Your content was uploaded!',
                    text: 'You will receive a notification once the content is approved',
                    icon: 'success',
                })
                vm.$router.replace('/contributor/videos')
            }
        },

        mounted () {
            // console.log('media')
            const vm = this
            vm.loadMedia()
        }

    }
</script>

<style lang = 'scss'  scoped>

</style>
