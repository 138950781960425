<template lang='pug'>
  v-container(fluid)
    v-form(ref="form")
      v-snackbar(v-model='snackbar',  :color='color',  top  :timeout="6000")
          | {{ message }}
          v-btn(dark='', text='', @click='snackbar = false')
            | Close
      //- thumbnail dialog
      v-dialog(v-if="posterDialog" persistent hide-overlay :value='posterDialog' max-width='800px' transition="dialog-bottom-transition")
        div(style ='position:relative; height: 100%;')
          thumbnailDialog(v-if="posterDialog" :thumbnail='poster' @save="uploadFile('thumbnail')" @close="posterDialog=false")
      //-form
      v-row
        v-col(cols="12" md="12")
          v-select(v-model="type" :items="types" menu-props="auto" label="Select type of media" single-line @change="resetMedia")
      v-row(v-if="type")
        v-col(cols="12" sm="6")
          v-row
            v-col(cols="12")
              //- autocomplete
              gmap-autocomplete#map(ref="address" @place_changed="getAddressData" :options="autocompleteOptions" placeholder="Type the location").location-input
            v-col(cols="12")
              //- Tittle
              validation-provider(v-slot="{ errors }" rules="required" name="Title")
                  v-text-field(v-model="content.title" :error-messages="errors" color="secondary" label="Title*" validate-on-blur="")
            v-col(cols="12")
              //- Description
              validation-provider(v-slot="{ errors }" rules="required" name="Description")
                  v-textarea(v-model="content.description" :error-messages="errors" color="secondary" label="Description*" validate-on-blur="")
            v-col(cols="12")
              //- Image
              span(v-if="type==='Image'")
                input(type="file" ref="thumbnail" @change="uploadFile('thumbnail')" accept="image/*").d-none
                v-progress-linear(v-if="loadingImage" indeterminate="" color="green")
                v-btn(block v-else-if="!content.thumbnail || update" @click="$refs.thumbnail.click()" color="secondary") Upload Image
                  // v-file-input(full-width dark height="100%" prepend-icon="mdi-camera" :clearable="false" accept="image/*" v-model="uploadImage" label="Upload" @change="uploadFile('thumbnail')")
              //- Video
              span(v-if="type==='Video'")
                input(type="file" ref="video" @change="uploadFile('video')" accept="video/*").d-none
                v-progress-linear(v-if="loadingVideo" indeterminate="" color="green")
                v-btn(block v-else-if="!content.url || update" @click="$refs.video.click()" color="secondary") Upload Video
                  // v-file-input(full-width dark height="100%" prepend-icon="mdi-camera" accept="video/*" :clearable="false" v-model="uploadVideo" label="Video"  @change="uploadFile('video')")
                //- Duration
                validation-provider(v-slot="{ errors }" rules="required" name="Duration")
                  v-text-field(v-model="content.duration"  :error-messages="errors" color="secondary" label="Video Duration (seconds)*" validate-on-blur="" disabled)
            v-col(cols="6")
              //- Category
              validation-provider(v-slot="{ errors }" rules="required" name="Category")
                  v-autocomplete(v-model="content.category" :items="categories" color="secondary" label="Category*" validate-on-blur="")
            v-col(cols="6")
              //- Subcategory
              validation-provider(v-slot="{ errors }" rules="required" name="Category")
                  v-autocomplete(v-model="content.subcategory" :items="subCategories" color="secondary" label="Sub Category" validate-on-blur="")
            v-col(cols="6")
              //- Date
              v-menu(v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y="" min-width="290px")
                  template(v-slot:activator="{ on }")
                      v-text-field(v-model="content.date" label="Date of incident" prepend-icon="mdi-calendar" readonly="" v-on="on")
                  v-date-picker(v-model="content.date" @input="menu2 = false")
            v-col(cols="6")
              //- Time
              v-menu(ref="menu" v-model="menu_time" :close-on-content-click="false" :nudge-right="40" :return-value.sync="time" transition="scale-transition" offset-y="" max-width="290px" min-width="290px")
                  template(v-slot:activator="{ on }")
                      v-text-field(v-model="content.time" label="Time of incident" prepend-icon="mdi-clock" readonly="" v-on="on")
                  v-time-picker(v-if="menu_time" v-model="content.time" full-width="" @click:minute="$refs.menu.save(time)")
        v-col(cols="12" sm="6")
            v-row
                v-col(cols="12" md="12")
                    v-skeleton-loader.mx-auto(:transition='transition' v-if='!content.url'  type='card')
                    v-card(v-else)
                        span(v-if="type === 'Video'").text-center
                          video-player#video.white--text.align-end(class="video-player-box" ref="videoPlayer" :options="playerOptions" :playsinline="true" :crossOrigin="'Anonymous'" @canplay="onPlayerCanplay($event)")
                          canvas#canvasVideo(style="overflow:none").d-none
                          v-row
                            v-col(cols="12").text-center
                              v-btn(@click="capture" small rounded color="secondary") Capture thumbnail
                          v-card-text.body-1.font-weight-light.pa-1.text-justify.primary--text
                          | You can capture an image of any second of the video, this image will be used as a reference for users who want to buy the video
                        span(v-else)
                          v-row
                            v-col(cols="12")
                              v-img#parent(height="200px" :src="content.url")
                            v-col(cols="12")
                              v-img(:src="content.thumbnail" contain)
                              v-card-text.body-1.font-weight-light.pa-1.text-justify.primary--text
                              | This way, users will see the images before buying them
                v-col(cols="12" md="12").text-center
                  v-card-text.body-1.font-weight-light.pa-1.text-justify(v-if="type==='Video'")
                    | The videos you upload will cost $149.95. For each download, 25% of the total cost will be awarded to your earnings once the video is purchased.
                  v-card-text.body-1.font-weight-light.pa-1.text-justify(v-else)
                    | The images you upload will cost $99.95. For each download, 25% of the total cost will be awarded to your earnings once the image is purchased.
                  v-card-text.body-1.font-weight-light.pa-1.text-justify
                    | IMPORTANT: Other users will be able to buy your media when it is approved by Scene Finder
                v-col(cols="12" md="12").text-center
                    v-btn(color="primary" block @click='save' :loading="loadingButton" :disabled="!content.title || !content.description || !content.thumbnail || !content.url || !content.category || !content.location")
                        | Upload
</template>
<script>
  /* eslint-disable */
  import moment from 'moment'
  import 'video.js/dist/video-js.css'
  import { videoPlayer } from 'vue-video-player'
  import S3 from 'aws-s3'
  export default {
    components: {
      videoPlayer,
      thumbnailDialog: () => import('../dialogs/Videos/ThumbnailDialog'),
    },
    props: {
      model: Object,
      update: Boolean,
      contentUpload: {
        type: Object,
        default: () => ({
          type: undefined,
        })
      },  
      onSave: {
        type: Function,
        required: true,
      },
    },
    data () {
      return {
        transition: 'scale-transition',
        uploadImage: {},
        uploadVideo: {},
        loadingImage: false,
        loadingVideo: false,
        subCategories: [],
        categories: [
          'Accident',
          'Vandalism',
          'Home invasion',
          'Crimes',
          'Historic events',
          'Humanitarian',
          'Unusual vacation spots',
        ],
        menu2: false,
        menu_time: false,
        time: null,
        message: '',
        snackbar: false,
        color: 'success',
        type: '',
        types: ['Image', 'Video'],
        posterDialog: false,
        poster: '',
        autocompleteOptions: {
          componentRestrictions: {
            country: [
              'us',
            ],
          },
        },
        loadingButton: false,
        content: {},
        addressInput: ''
      }
    },
    computed: {
      media () {
        return this.$store.getters['uploads/getMedia']
      },
      playerOptions () {
        return {
          language: 'en',
          playbackRates: [0.5, 1.0, 1.5, 2.5, 3.0],
          sources: [{
            type: 'video/mp4',
            src: this.content.url,
          }],
          controlBar: {
            timeDivider: false,
            durationDisplay: true,
          },
          poster: this.content.thumbnail,
          fluid: true,
        }
      },
      config () {
        return {
          bucketName: 'scenefinder',
          dirName: 'uploads', /* optional */
          region: 'us-east-2',
          accessKeyId: 'AKIA4UWEBIN7X6F6727H',
          secretAccessKey: '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',
          s3Url: 'https://scenefinder.s3.us-east-2.amazonaws.com', /* optional */
        }
      },
      S3Client () {
        return new S3(this.config)
      },
    },
    watch: {
      'content.category': function (val) {
        const vm = this
        vm.content.subcategory = ''
        switch (val) {
          case 'Accident':
            vm.subCategories = ['backing', 'door opening', 'driving on wrong side', 'intersection', 'lane change', 'multiple vehicle', 'other', 'rear end', 'single vehicle', 'struck parked vehicle']
            break
          case 'Vandalism':
            vm.subCategories = ['break-in', 'club (bat,pipe)', 'keyed', 'other', 'scratched', 'shooting']
            break
          case 'Home invasion':
            vm.subCategories = ['burglary', 'fire', 'other', 'trespassing', 'vandalism']
            break
          case 'Crimes':
            vm.subCategories =  ['animal cruelty', 'assault & battery', 'child abuse', 'domestic abuse', 'elder abuse', 'kidnapping', 'murder', 'other', 'robbery']
            break
          case 'Humanitarian':
            vm.subCategories = ['aid relief', 'education', 'financial assistance', 'food supplies', 'infrastructure', 'medical services', 'medical supplies', 'other', 'saving the earth', 'surgery', 'water supply']
            break
          case 'Historic events':
            vm.subCategories = ['climbing a famous mountain', 'getting into the Guinness Book of Work Record', 'milestone events', 'other']
            break
          case 'Unusual vacation spots':
            vm.subCategories = ['cave', 'desert', 'ice resort', 'island', 'mountain', 'other', 'wilderness']
            break
          default :
            vm.subCategories = []
            break
        }
      },
    },
    created () {
      const vm = this
      vm.content = {
        ...vm.model
      }
      if (vm.contentUpload.type) {
        if (vm.contentUpload.type.includes('image')) {
          vm.type = 'Image'
          vm.uploadImage = vm.contentUpload
          vm.uploadFile('thumbnail')
        } else if (vm.contentUpload.type.includes('video')) {
          vm.type = 'Video'
        }
      }
      if (vm.update) {
        if (vm.content.type.includes('image')) {
          vm.type = 'Image'
        } else {
          vm.type = 'Video'
        }
      }
    },
    beforeDestroy () {
      this.resetMedia()
    },
    methods: {
      capture () {
        const vm = this
        let video = document.getElementById('video').getElementsByClassName('vjs-tech')[0]
        let canvas = document.getElementById('canvasVideo');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        let image = new Konva.Image({
          image: canvas,
        });
        vm.poster = image.toDataURL()
        vm.posterDialog = true
        vm.uploadImage = vm.dataURLtoFile(vm.poster, 'thumbanail.png')
      },
      onPlayerCanplay (player) {
        const vm = this
        vm.content.duration = Math.floor(player.cache_.duration / 60) + ':' + Math.floor(player.cache_.duration % 60)
      },
      validateUpload () {
        const vm = this
        let found = null
        if (vm.media) {
          found = vm.media.find(element => element.type === vm.content.type && element.lastModified === vm.content.lastModified && element.size === vm.content.size)
        }
        if (found) {
          vm.message = 'The Media you are trying to upload is already registered'
          vm.snackbar = true
          vm.color = 'red'
          // Reset loading
          vm.loadingImage = false
          vm.loadingVideo = false
          // Reset media
          vm.resetMedia()
        }
        return found
      },
      setMetadata (file) {
        const vm = this
        vm.content.type = file.type
        vm.content.lastModified = file.lastModified || Date.now()
        vm.content.size = file.size
        vm.content.date = moment(vm.content.lastModified).format('MM-DD-YYYY')
        vm.content.time = moment(vm.content.lastModified).format('HH:mm')
      },
      dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
      },
      blurImage (location, file) {
        const vm = this
        Konva.Image.fromURL(location, function (image) {
          image.setAttrs({
            width: 1000,
            height: 1000,
            blurRadius : 150
          });
          image.cache()
          image.filters([Konva.Filters.Blur])
          // Crear file a partir de url de imagen para subir a s3
          // console.log(image.toDataURL())
          const imageToUpload = vm.dataURLtoFile(image.toDataURL(), file.name)
          const newName = Date.now().toString()
          // console.log(newName)
          vm.S3Client.uploadFile(imageToUpload, newName).then(data => {
            // console.log(data)
            vm.content.thumbnail = data.location
            vm.loadingImage = false
          }).catch(err => {
            // console.log(err)
            vm.loadingImage = false
          })
        });
      },
      uploadFile (type) {
        const vm = this
        let file
        console.log(vm.$refs.thumbnail)
        // Validar tipo de media
        if (type === 'thumbnail') {
          if (vm.$refs.thumbnail) {
            file = vm.$refs.thumbnail.files[0]
          } else {
            file = vm.uploadImage
          }
          console.log(file)
          if(vm.type === 'Image') {
            vm.loadingImage = true
            vm.setMetadata(file)
          }
        } else {
          if (vm.$refs.video) {
            file = vm.$refs.video.files[0]
          } else {
            file = vm.vm.uploadVideo
          }
          if (vm.type === 'Video') {
            vm.loadingVideo = true
            vm.setMetadata(file)
          }
        }
        // Validar contenido
        if (!vm.validateUpload()) {
          const newName = Date.now().toString()
          vm.S3Client.uploadFile(file, newName).then(data => {
            if (type === 'thumbnail') {
              if (vm.type === 'Image') {
                vm.content.url = data.location
                vm.blurImage(data.location, file)
              } else {
                vm.content.thumbnail = data.location
                vm.loadingImage = false
                vm.posterDialog = false
              }
            } else {
              vm.content.url = data.location
              vm.loadingVideo = false
            }
          }).catch(err => {
            vm.loadingImage = false
            vm.loadingVideo = false
          })
        }
      },
      getAddressData (place) {
        // Separarar direccion
        const locations = place.adr_address.split(",")
        // Variable
        let location = {
          direction: '',
          locality: '',
          state: '',
        }
        // Recorerrer direcciones
        locations.forEach(function (element){
          if (element.includes('street-address')){
            location.direction = element.substring(element.indexOf('>')+1, element.lastIndexOf("</span>"))
          }
          if (element.includes('locality')){
            location.locality = element.substring(element.indexOf('>')+1, element.lastIndexOf("</span>"))
          }
          if (element.includes('region')){
            location.state = element.substring(element.indexOf('>')+1, element.indexOf("</span>"))
          }
        })
        // Setear variable
        this.content.location = {
          direction: location.direction || location.locality,
          locality: location.locality,
          state: location.state,
          country: 'USA',
          latlng: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
        }
      },
      cancel () {
        this.$emit('cancel')
      },
      resetMedia () {
        const vm = this
        console.log(vm.$refs.address)
        if (!vm.update) {
          vm.content = {}
          vm.uploadImage = undefined
          vm.uploadVideo = undefined
        } else {
          vm.content.url = ''
          vm.content.thumbnail = ''
          vm.content.duration  = ''
          vm.content.location = ''
          vm.addressInput = ''
        }
      },
      save () {
        const vm = this
        console.log(vm.content)
        vm.loadingButton = true
        if (!vm.update) {
          vm.$store.dispatch('uploads/createUpload', vm.content).then(() => {
            vm.loadingButton = false
            vm.onSave()
            vm.$emit('close')
          })
        } else {
          vm.$store.dispatch('uploads/updateUpload', vm.content).then(() => {
            vm.loadingButton = false
            vm.onSave()
            vm.$emit('close')
            this.$emit('close')
          })
        }
      },
    },
  }
</script>

<style>
    .location-input{
        width: 100%;
        border-bottom: 1px solid #d2d2d2;
    }
    .pac-container{
      z-index:9999 !important
    }
</style>
